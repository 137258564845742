import { formatISOString } from './format-time';

interface Options {
  patternedStringKeys?: string[]
}

// for timestamp
function isTimestampKey(key: string) {
  return key.endsWith('At');
}

function normalizeTimestamp(timestamp: string) {
  return timestamp === '' ? null : formatISOString(timestamp);
}

// for patterned string
function normalizePatternedString(string: string) {
  return string === '' ? null : string;
}

export function normalizeRequestData<TData = Record<string, any>>(
  data: TData,
  { patternedStringKeys = [] }: Options = {}
): TData {
  const normalizedData = {} as TData;

  Object.entries(data)
    .forEach(([key, value]) => {
      // for timestamp
      if (isTimestampKey(key)) {
        normalizedData[key] = normalizeTimestamp(value);
        return;
      }

      // for patterned string
      if (patternedStringKeys.includes(key)) {
        normalizedData[key] = normalizePatternedString(value);
        return;
      }

      normalizedData[key] = value;
    });

  return normalizedData;
}
